import React from "react";
import { ArrowCircleLeftIcon } from "@heroicons/react/solid"
import { navigate } from "gatsby"

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => (
    <>
        {browser && <div className="flex flex-col mt-10 items-center mx-10 text-center">
            <div className="text-3xl font-bold">Page not found</div>
            <div className="mt-6">Oops, the page you are looking for has been removed or relocated.</div>
            <button onClick={() => { navigate(-1) }} 
                    className="text-sm text-center font-semibold rounded-full text-white bg-wedgewood-600 flex flex-row items-center px-6 py-2 mt-6" >
                        <ArrowCircleLeftIcon className="h-6 w-6 mr-3"/>
                        <div>Go back</div>
            </button>
        </div>}
    </>
)

export default NotFoundPage